<template>
  <div>
    <c-table
      ref="processTable"
      title="선택 공정의 HAZOP 이력"
      :columns="grid1.columns"
      :data="grid1.data"
      gridHeight="350px"
      :filtering="false"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      :hide-bottom="true"
      @rowClick="rowClick"
    >
    </c-table>
    <c-table
      ref="processTable"
      title="선택 도면의 시나리오 목록"
      :columns="grid2.columns"
      :data="grid2.data"
      gridHeight="350px"
      :merge="grid2.merge"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="false"
    >
    </c-table>
    <c-table
      ref="processTable"
      title="선택 도면의 Punch-list"
      :columns="grid3.columns"
      :data="grid3.data"
      :merge="grid3.merge"
      gridHeight="350px"
      :filtering="false"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      :hide-bottom="true"
      @linkClick="linkClick"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'dash-hazop',
  props: {
    searchParam: {
      type: Object,
      default: function() {
        return {
          plantCd: '',
          processCd: '',
        };
      },
    },
  },
  data() {
    return {
      grid1: {
        columns: [
          {
            name: 'assessmentYear',
            field: 'assessmentYear',
            label: '평가년도',
            align: 'center',
            sortable: true,
          },
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'diagramTitle',
            field: 'diagramTitle',
            // 도면명
            label: '도면명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'assessNodeCnt',
            field: 'assessNodeCnt',
            label: '평가노드 수',
            align: 'right',
            sortable: true,
            type: 'cost'
          },
          {
            name: 'improveCnt',
            field: 'improveCnt',
            label: '펀치리스트 수',
            align: 'right',
            sortable: true,
            type: 'cost'
          },
        ],
        data: [],
      },
      grid2: {
        height: '600px',
        merge: [
          { index: 0, colName: 'nodeTitle' },
          { index: 1, colName: 'deviationName' },
          { index: 2, colName: 'riskOccurrenceCause' },
        ],
        columns: [
          {
            name: 'nodeTitle',
            field: 'nodeTitle',
            description: 'nodeNo',
            label: '노드',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'deviationName',
            field: 'deviationName',
            label: '이탈',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            label: '원인',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceResult',
            field: 'riskOccurrenceResult',
            label: '결과',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            label: '개선전',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                label: '강도',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                label: '위험도',
                align: 'center',
                style: 'width:70px',
                sortable: false,
              },
            ]
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: '개선후',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                label: '강도',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                label: '위험도',
                align: 'center',
                style: 'width:70px',
                sortable: false,
              },
            ]
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            label: '평가일',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            label: '평가자',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
        allData: [],
      },
      grid3: {
        merge: [
          { index: 0, colName: 'nodeNm' },
          { index: 1, colName: 'col11' },
          { index: 2, colName: 'col12' },
          { index: 3, colName: 'col12' },
        ],
        columns: [],
        data: [],
      },
      listUrl: '',
      scenarioListUrl: '',
      improveListUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
    searchParam: {
      handler: function () {
        this.getList();
      },
      deep: true,
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
        this.grid3.columns = [
          {
            name: 'nodeTitle',
            field: 'nodeTitle',
            description: 'nodeNo',
            label: '노드',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'deviationName',
            field: 'deviationName',
            label: '이탈',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'nodeVariableDesignIntent',
            field: 'nodeVariableDesignIntent',
            label: '설계의도',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            label: '원인',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceResult',
            field: 'riskOccurrenceResult',
            label: '결과',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            type: 'link',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'ibmStepCd',
            field: 'ibmStepCd',
            label: '진행상태',
            align: 'center',
            style: 'width:100px',
            type: 'tag',
            colorItems: _result,
            sortable: false
          },
          {
            name: 'improveRequest',
            field: 'improveRequest',
            label: '요청부서정보',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            label: '조치완료요청일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ];
      });
      // api scope
      this.listUrl = selectConfig.ram.dashboard.hazop.url;
      this.scenarioListUrl = selectConfig.ram.hazop.scenario.list.url;
      this.improveListUrl = selectConfig.ram.hazop.riskReduce.list.url;

      this.getList();
    },
    getList() {
      if (!this.searchParam.processCd) {
        this.grid1.data = [];
        this.grid2.data = [];
        this.grid3.data = [];
        return;
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam
      this.$http.request((_result) => {
        this.grid1.data = _result.data;
        this.grid2.data = [];
        this.grid3.data = [];
      },);
    },
    rowClick(row) {
      this.$http.url = this.scenarioListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: row.ramRiskAssessmentPlanId,
        processCd: row.processCd,
        psiDiagramId: row.psiDiagramId,
      }
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
      },);

      this.$http.url = this.improveListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: row.ramRiskAssessmentPlanId,
        processCd: row.processCd,
        psiDiagramId: row.psiDiagramId,
      }
      this.$http.request((_result) => {
        this.grid3.data = _result.data.imprs;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '개선 상세'; 
      this.popupOptions.param = {
        isSearch: true,
        sopImprovementId: row.sopImprovementId,
        ibmTaskTypeCd: row.ibmTaskTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
